
import router from '@/router/clean';
import { computed, defineComponent, onMounted, ref } from "vue";
import { store } from "@/store";

export default defineComponent({
  name: "kt-quick-links-menu",
  components: {},
  setup() {
    
    const permissions = computed(() => {
        return store.getters.getUserPermissions
    })

    const navigateTo = async (gotoLink, activeTab) => {
        await router.push({ name: gotoLink });
    };

    return { navigateTo, permissions };
  },
});
