
import { defineComponent, computed, onMounted, watch, ref } from "vue";
import { store } from "@/store";
import { useRoute, useRouter } from "vue-router";
import KTAside from "@/layout/aside/Aside.vue";
import KTHeader from "@/layout/header/Header.vue";
import KTFooter from "@/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
import KTToolbar from "@/layout/toolbar/Toolbar.vue";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
import KTUserMenu from "@/layout/header/partials/ActivityDrawer.vue";
import KTLoader from "@/components/Loader.vue";
import KTCreateApp from "@/components/modals/wizards/CreateAppModal.vue";
// import KTExplore from "@/layout/extras/Explore.vue";
// import LQExplore from "@/components/quotes/List-Quote.vue";
import KTDrawerMessenger from "@/layout/extras/DrawerMessenger.vue";
import { Actions } from "@/store/enums/StoreEnums";
import {
  MenuComponent,
  DrawerComponent,
  ScrollComponent,
} from "@/assets/ts/components/index";
import { removeModalBackdrop } from "@/core/helpers/dom";
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
} from "@/core/helpers/config";
import Customers from "@/core/services/Callable/Customers";
import { customer } from "@/store/stateless/store";
import Auths from "@/core/services/Callable/Auths";
import socket from "@/main-socket";
import { ElNotification } from "element-plus";

export default defineComponent({
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTFooter,
    KTToolbar,
    KTScrollTop,
    KTCreateApp,
    KTUserMenu,
    // KTExplore,
    // LQExplore,
    KTDrawerMessenger,
    KTLoader,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    const logoutChannel = computed(() => {
      return (store.getters.myId) ? `logout-${store.getters.myId}` : '';
    });

    const autoLogout = () => {
      console.log("logout socket trigger");
      Auths.setAuthNull()
    }

    const datetime = ref<any>(null);
    const respo = ref<any>([]);


    onMounted(() => {
      //check if current user is authenticated
      // if (!store.getters.isUserAuthenticated) {
      //   router.push({ name: "sign-in" });
      // }

      // console.log(logoutChannel.value)
      socket
        .channel(logoutChannel.value)
        .listen("LoginEvent", (data: any) => {
          console.log("mounted logout socket trigger");
          Auths.setAuthNull()
        });
      
      

      let user = store.state.auth.user;
      let agent = store.getters.showByRole([1,9,13]) ? "all" : store.getters.myId;
      if (user && user.role_id != 1) {
        // agent = user.id;
        agent = store.getters.showByRole([1,9,13]) ? -1 : store.getters.myId;
        customer.colors = {
          btnBgColor: "#fff",
          btnColor: "#000",
          slcBgColor: "#222261",
          slcColor: "#fff",
        };
      }
      Customers.setFilter({
        agent,
        customer_status_id: -1,
        end_date: "",
        index: 1,
        keyword: "",
        lead_status_id: -1,
        page: 1,
        per_page: 50,
        start_date: "",
        task_due_dates: -1,
        hot_enquiry: -1,
      });

      DrawerComponent.bootstrap();
      ScrollComponent.bootstrap();
      DrawerComponent.updateAll();
      ScrollComponent.updateAll();

      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);


      socket
        .channel("health-channel-" + store.getters.myId)
        .listen("Health.HealthPopupNotification", async (response: any) => {
          // console.log("HealthPopupNotification 3", response);
          respo.value.push(response);

          if (response.is_popup) {
            let contentHtml = (await response.url) != ""
                              ? "<a href='#" + response.url + "'>" + response.content + "</a>"
                              : response.content;
            setTimeout(async () => {
              if (datetime.value !== response.dateTime) {
                await respo.value.filter(
                  (item) => item.dateTime === response.dateTime
                );
                await respo.value.forEach((x) => {
                  popupNotification(contentHtml, x.isNotifStay);
                });

                respo.value = [];
              }
              datetime.value = response.dateTime;
            }, 1000);
          }
        });
      
    });

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);

        DrawerComponent.hideAll();

        // check if current user is authenticated
        // if (!store.getters.isUserAuthenticated) {
        //   router.push({ name: "sign-in" });
        // }

        removeModalBackdrop();
      }
    );

    const popupNotification = (contentHtml, isNotifStay) => {
      ElNotification({
        customClass: isNotifStay ? "notification-skye" : "notification-success",
        title: "Notification",
        message: contentHtml,
        duration: isNotifStay ? 10000 : 2500,
        type: isNotifStay ? "info" : "success",
        dangerouslyUseHTMLString: true,
      });
    };
    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
      logoutChannel,
      autoLogout
    };
  },
});
