
import { defineComponent, ref, computed } from "vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import BaiSearch from "@/layout/header/partials/BaiSearch.vue";
import QuickLinks from "@/layout/header/partials/QuickLinks.vue";
import BaiNotification from "@/layout/header/partials/BaiNotification.vue";
import Auths from '@/core/services/Callable/Auths';
import Etcs from '@/core/services/Callable/Etcs';
import router from '@/router/clean';

import { store } from "@/store";

export default defineComponent({
  name: "topbar",
  components: {
    // KTNotificationsMenu,
    // KTQuickLinksMenu,
    QuickLinks,
    BaiSearch,
    BaiNotification
  },

  setup() {
    const SearchDomRef = ref();
    const user = computed(() => {
      return store.getters.getAuthUser;
    })

    const dialogVisible = ref(false);
    const swtichLoading = ref(false);

    const notificationCount = computed(() => {
      return store.state.etc.notificationCount;
    });

    const switchRole = async(e)=>{
        // dialogVisible.value = false
        swtichLoading.value = true
        await Auths.switchRole();
        await Etcs.getRoutes(); 

        // const defaultRoute = user.value.role_id ==  4 ? '/policy/assigned' : '/customers/list';
        const defaultRoute = '/dashboard';

        await router.push({ path: defaultRoute }).then(() => { router.go(0) })
        // await router.push({ name: 'dashboard' })
        window.location.reload()
    }

    return {
      store,
      SearchDomRef,
      user,
      dialogVisible,
      switchRole,
      swtichLoading,
      notificationCount
    };
  },
});
