
import { defineComponent, computed } from "vue";
// import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import { toolbarWidthFluid } from "@/core/helpers/config";
import Customers from "@/core/services/Callable/Customers";
import { Plus, User } from "@element-plus/icons-vue";
import router from "@/router/clean";
import { modal } from "@/store/stateless";
import { toolbars } from "@/core/utils";
import { store } from "@/store";
import moment from "moment";

export default defineComponent({
  name: "KToolbar",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  // components: {
  //   Dropdown1,
  // },
  setup() {
    // const openQuickLeadModal = () => {
    //   Customers.setQuckLeadModal(true);
    // }

    const currentRoute = (): string => {
      if (router.currentRoute !== undefined)
        return router.currentRoute.value.name?.toString() || "";
      return "s";
    };

    const myFunction = (value: number) => {
      switch (value) {
        case 2:
          openRoute("AddDetail");
          break;
        case 4:
          openRoute("AddCorporate");
          break;
        case 3:
          openImportMdal();
          break;
        default:
          modal.quicklead = true;
          break;
      }
    };

    const openImportMdal = () => {
      Customers.setImportModal(true);
    };

    const openRoute = (value) => {
      // const route = router.resolve({ name: value });
      // window.location.href = route.href;
      router.push({ name: value }).then(() => router.go(0))
    };

    const dateSummary = computed(() => {
        const filter =  store.state.dashboard.filter;

        if(filter && filter.start_date && filter.end_date) {
          const start = moment(filter.start_date).format('DD')
          const end = moment(filter.end_date).format('DD')
          const month = moment(filter.end_date).format('MMM')
          const year = moment(filter.end_date).year()

          return `${start} - ${end} ${month} ${year}`
        }
        
        return ''
      })

    return {
      dateSummary,
      store,
      toolbars,
      myFunction,
      Plus,
      User,
      openRoute,
      toolbarWidthFluid,
      currentRoute,
      router
    };
  },
});
