
  import { defineComponent, onMounted, ref, computed, toRefs } from "vue";
  import Etcs from "@/core/services/Callable/Etcs";
  import { store } from "@/store";
  import socket from '@/main-socket'

  export default defineComponent({
    name: "lt-notification",
    components: {},

    setup(props, context) {
      let page = ref < any > (0);
      let enableNotificationCall = ref < boolean > (true);
      let limit = ref(1);
      const loading = ref(true)

      let notificationList = ref<Array<any>>([]);

      const user = computed(() => {
        return store.getters.currentUser;
      });

      const notificationCount = computed(() => {
        return store.state.etc.notificationCount;
      });

      let baseUrl = ref < string > ("");
      onMounted(async () => {
        await Etcs.getUnreadNotificationCount()
        await loadDataFromServer()

        socket.channel("channel-" + store.getters.myId)
        .listen("UnReadNotification", (response) => {
          if (response.notification) {
             Etcs.getUnreadNotificationCount()
             enableNotificationCall.value = true
              page.value = 0
              notificationList.value = []
             loadDataFromServer()
          }
        });

      });

      const loadDataFromServer = async () => {
        // console.log('calling ...');

        if (enableNotificationCall.value) {
          page.value = page.value + 1;
          enableNotificationCall.value = false;
          loading.value = true;

          await Etcs.getRecentNotifications({ per_page: 5 , index:page.value }).then((data) => {
            enableNotificationCall.value = page.value < data.last_page ? true : false;
            loading.value = false;
            notificationList.value = notificationList.value.concat(data?.data);
          })
        }
      };
      const makeNotificationRead = async () => {
        await Etcs.readNotification()
      };

      const updateIsClick = async (notification) => {
        const params = {
          notification_id: notification.id,
        };
        console.log("params", params);
        await Etcs.updateNotificationClick(params).then((data) => {
          enableNotificationCall.value = true
          page.value = 0
          notificationList.value = []
          loadDataFromServer()
        })
      };

      return {
        loadDataFromServer,
        baseUrl,
        makeNotificationRead,
        user,
        updateIsClick,
        notificationList,
        loading,
        notificationCount
      };
    },
  });
